// import http from "@http"
// import env from "@api

// 获取诈骗数据
export function getDetailTabDataFun(data) {
    console.log(data);
    return Promise.resolve([
        {

        }
    ])
}

// 获取诈骗类型
export function getFraudTypeListFun() {
    return Promise.resolve([
        // {
        //     id: 1,
        //     code: "dianhua",
        //     name: "电话诈骗"
        // },
        // {
        //     id: 2,
        //     code: "daikuan",
        //     name: "贷款"
        // },
        // {
        //     id: 3,
        //     code: "maicha",
        //     name: "卖茶"
        // },
    ])
}